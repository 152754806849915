import React from "react"
import Layout from "../components/layout"
import { useLanguages } from "../hooks/useLanguages"

const NotFoundPage = ({ location }) => {	
  
  const[currLanguage] = useLanguages()
  
  return(
    <Layout location={location} pageLanguage={currLanguage}>
      <div className="bg-purple-100 max-w-7xl mx-3 md:mx-6 xl:mx-auto rounded-xl grid place-content-center text-center pt-10 md:pt-16 lg:pt-20 pb-5 px-5 mb-10">
        
        <h1 className="special-heading-big text-gray-500 py-5 my-5">404</h1>
        
        <p className="text-gray-500 text-center m-0 p-0 text-md md:text-xl mt-5 md:mt-10 pb-1">Looks like this page has rolled out of our circle of content.</p>
       
        <p className="text-gray-500 text-center text-xs md:text-base pb-5">Don't worry, while we fix that, you can always return to the circle.</p>
        
        <a href="/" className="vc-new-orange-btn-rounded-full text-white mx-auto">To the Homepage</a>
        
        <div className="w-5/6 md:w-1/2 lg:w-2/6 mx-auto grid my-12" style={{ gridTemplateColumns: '2fr 1fr 2fr' }}>
          <div className="border-b my-auto border-gray-darklight"></div>
          <p className="m-0 p-0 text-gray-500 text-sm">OR</p>
          <div className="border-b my-auto border-gray-darklight"></div>        
        </div>

        <div>
          <h2 className="text-xl md:text-3xl text-center text-gray-500 mb-5">Explore our products?</h2>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 md:px-3 lg:px-0 2xl:px-6">
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Rewards-2.webp" alt="Hoempage-Rewards-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Rewards and Recognition platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/rewards-recognition/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Rewards</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Vfit-2.webp" alt="Hoempage-vfit-1" width="300" height= "231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Wellness <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="https://www.vantagefit.io/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Fit</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Pulse-2.webp" alt="Hoempage-pulse-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Feedback <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/employee-survey/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Pulse</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Perks-2.webp" alt="Hoempage-perks-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Corporate Discounts <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/employee-discount/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Perks</a></div>
            </div>
          </div>
        </div>
      </div>
	  </Layout>
  )
}

export default NotFoundPage
